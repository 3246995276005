import * as React from 'react'
import * as Tooltip from 'react-tooltip'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { axios, API, ILocation, IDevice } from '@getgreenline/homi-shared'
import { LocationApi } from '@getgreenline/locations'
import { LoadingAnimation } from '../../../components/LoadingAnimation'
import { message } from 'antd'
import { parseErrorMsg } from '../../../utilities/helpers'

interface Props {
  companyId: number
}

interface ILocationContract extends ILocation {
  isHidden: boolean
}

interface State {
  locations?: ILocationContract[]
  devices?: IDevice[]
  addDeviceLocationId?: number
  deviceToChangeName?: IDevice
  newDeviceName: string
  deviceId: string
  devicePassword: string
}

export class LocationsAndDevices extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      deviceId: '',
      devicePassword: '',
      newDeviceName: '',
    }
  }

  componentDidMount() {
    this.refreshData()
  }

  async refreshData() {
    const { companyId } = this.props
    const locations = await axios.get(`/api/v1/admin/companies/${companyId}/locations`, {
      params: {
        limit: 30,
        offset: 0,
      },
    })

    const devices = await API.getDevices(companyId)
    this.setState({
      locations: locations.data.data,
      devices: devices,
    })
  }

  addDevice() {
    axios
      .post(`/api/v1/admin/device`, {
        email: this.state.deviceId,
        name: 'device',
        password: this.state.devicePassword,
        entityIds: [this.state.addDeviceLocationId],
      })
      .then((response) => {
        this.setState({
          addDeviceLocationId: undefined,
        })
        message.success('New device added')
        this.refreshData()
      })
      .catch((error) => {
        message.error(parseErrorMsg(error) || 'There was an error')
      })
  }

  async changeDeviceName() {
    const { deviceToChangeName, newDeviceName } = this.state
    if (deviceToChangeName && newDeviceName) {
      try {
        await axios.put(`/api/v1/admin/device/${deviceToChangeName.id}`, {
          deviceId: newDeviceName,
        })
        this.setState({
          deviceToChangeName: undefined,
        })
        message.success('Device ID changed')
        this.refreshData()
      } catch (error) {
        message.error(parseErrorMsg(error) || 'There was an error')
      }
    }
  }

  unDeleteLocation = async (locationId: number) => {
    const { companyId } = this.props

    try {
      await LocationApi.unDeleteLocation(companyId, { id: locationId, companyId, isHidden: false })
      message.success('Location successfully undeleted')
      this.refreshData()
    } catch (err) {
      message.error(parseErrorMsg(err) || 'There was an error')
    }
  }

  deleteDevice = async () => {
    const { deviceToChangeName } = this.state

    const shouldDelete = confirm('Are you sure you want to delete this device ?')
    if (shouldDelete) {
      try {
        await axios.delete(`/api/v1/admin/devices/${deviceToChangeName?.id}`)
        await this.refreshData()

        message.success('Device succesfully removed')
      } catch (err) {
        message.error(parseErrorMsg(err) || 'There was an error')
      }
    }
    this.setState({ deviceToChangeName: undefined })
  }

  render() {
    const { locations, devices } = this.state

    if (!locations || !devices) {
      return <LoadingAnimation green />
    }

    return (
      <div className='background-shadow p-4'>
        <h5 className='text-muted'>
          <span className='mr-2'>Locations & devices</span>
        </h5>
        <br />
        <table className='table table-hover table-sm'>
          <tbody>
            {locations.map((location) => {
              const matchingDevices = devices.filter((device) => device.locationId === location.id)
              return (
                <React.Fragment key={location.id}>
                  <tr>
                    <td>
                      <span className='text-muted mr-2'>{location.id}</span>
                      <b>{location.name}</b>
                      {location.isHidden && (
                        <span
                          className='badge badge-danger text-left ml-2'
                          style={{ color: 'white', textIndent: 0 }}
                        >
                          Deleted
                        </span>
                      )}
                    </td>
                    <td className='text-right'>
                      {location.isHidden ? (
                        <a onClick={() => this.unDeleteLocation(location.id)}>
                          <span className='fa fa-plus mr-2' />
                          Undelete
                        </a>
                      ) : (
                        <a onClick={() => this.setState({ addDeviceLocationId: location.id })}>
                          <span className='fa fa-plus mr-2' />
                          Add device
                        </a>
                      )}
                    </td>
                    <td className='text-right'>
                      <span
                        className='fa fa-bars text-muted'
                        style={{ cursor: 'pointer' }}
                        data-tip
                        data-for={'location-' + location.id}
                      />
                      <Tooltip id={'location-' + location.id} place='right' effect='solid'>
                        <pre className='text-left text-white'>
                          {JSON.stringify(location, null, 2)}
                        </pre>
                      </Tooltip>
                    </td>
                  </tr>
                  {matchingDevices.map((device) => {
                    const isMerrco =
                      device.merrcoApiAuthKey &&
                      device.merrcoCompanyNumber &&
                      device.merrcoMerchantNumber &&
                      device.merrcoMerchantTerminalNumber
                    const isMoneris = device.terminalIpAddress && device.terminalPort
                    const merrcoBadge = isMerrco && (
                      <span className='badge badge-secondary mr-2'>Merrco</span>
                    )
                    const monerisBadge = isMoneris && (
                      <span className='badge badge-secondary mr-2'>Moneris</span>
                    )
                    return (
                      <tr key={device.id}>
                        <td style={{ paddingLeft: 80 }}>
                          <a
                            className='mr-2'
                            onClick={() =>
                              this.setState({
                                newDeviceName: device.deviceId,
                                deviceToChangeName: device,
                              })
                            }
                          >
                            {device.deviceId}
                          </a>
                          {merrcoBadge}
                          {monerisBadge}
                        </td>
                        <td className='text-right text-muted'>{device.id}</td>
                        <td className='text-right'>
                          <span
                            className='fa fa-bars text-muted'
                            style={{ cursor: 'pointer' }}
                            data-tip
                            data-for={device.id}
                          />
                          <Tooltip id={device.id} place='right' effect='solid'>
                            <pre className='text-left text-white'>
                              {JSON.stringify(device, null, 2)}
                            </pre>
                          </Tooltip>
                        </td>
                      </tr>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>

        <Modal
          isOpen={!!this.state.addDeviceLocationId}
          toggle={() => this.setState({ addDeviceLocationId: undefined })}
          autoFocus={false}
        >
          <ModalHeader>Add POS device</ModalHeader>
          <ModalBody>
            <div>
              <label>Location ID: {this.state.addDeviceLocationId}</label>
            </div>
            <div className='form-group'>
              <label>Device ID</label>
              <input
                className='form-control'
                value={this.state.deviceId}
                onChange={(e) => this.setState({ deviceId: e.target.value })}
              />
            </div>
            <div className='form-group'>
              <label>Device Password</label>
              <input
                className='form-control'
                value={this.state.devicePassword}
                onChange={(e) => this.setState({ devicePassword: e.target.value })}
              />
            </div>
            <button className='btn btn-success' onClick={() => this.addDevice()}>
              Add POS device
            </button>
          </ModalBody>
        </Modal>

        {this.state.deviceToChangeName && (
          <Modal isOpen={true} toggle={() => this.setState({ deviceToChangeName: undefined })}>
            <ModalHeader>Change POS device ID</ModalHeader>
            <ModalBody>
              <div className='form-group'>
                <label>Device ID</label>
                <div className='text-muted'>
                  Original device ID: {this.state.deviceToChangeName.deviceId}
                </div>
                <input
                  className='form-control'
                  value={this.state.newDeviceName}
                  onChange={(e) => this.setState({ newDeviceName: e.target.value })}
                />
              </div>
              <div className='alert alert-warning'>
                Make sure customers are told about all changes made to their devices!
              </div>
              <button className='btn btn-danger mr-2' onClick={() => this.deleteDevice()}>
                Remove device
              </button>
              <button className='btn btn-success' onClick={() => this.changeDeviceName()}>
                Change device ID
              </button>
            </ModalBody>
          </Modal>
        )}
      </div>
    )
  }
}
