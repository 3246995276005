import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { POSStore } from '../../../stores/POSStore'
import { EmployeeSelectModal } from './EmployeeSelectModal'

interface Props {
  posStore?: POSStore
}

interface State {
  showEmployeeSelectModal: boolean
}

@inject('posStore')
@observer
export class EmployeeNavbar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      showEmployeeSelectModal: false,
    }
  }

  togglePopover() {
    this.setState({
      showEmployeeSelectModal: !this.state.showEmployeeSelectModal,
    })
  }

  render() {
    const shift = this.props.posStore!.currentShift

    return (
      <span>
        {shift && shift.currentUserId && shift.currentUserName ? (
          <button
            id='employee-popover-target'
            className='btn btn-invisible btn-sm text-white'
            onClick={() => this.togglePopover()}
          >
            <u>{shift.currentUserName}</u>
          </button>
        ) : (
          <span id='employee-popover-target' onClick={() => this.togglePopover()}>
            <button className='btn btn-warning btn-sm'>
              <span className='fa fa-user mr-2' />
              Select employee
            </button>
          </span>
        )}
        {this.state.showEmployeeSelectModal && (
          <EmployeeSelectModal onHide={() => this.setState({ showEmployeeSelectModal: false })} />
        )}
      </span>
    )
  }
}
