import * as React from 'react'
import Select from 'react-select'
import CSVReader from 'react-csv-reader'
import { isString, isNumber } from 'util'
import {
  CreateSupplier,
  IBaseCategory,
  API,
  ICompany,
  ICategoryContract,
  Supplier,
} from '@getgreenline/homi-shared'
import { Alert, Icon, Checkbox, Progress, Skeleton, PageHeader, Tag } from 'antd'
import { browserHistory } from 'react-router'
import uniq from 'lodash.uniq'
import {
  CreateChildProduct,
  CreateProduct,
  MetaDataReservedKeys,
  ProductMetaData,
} from '../../Dashboard/DashboardProducts/AddProduct/ProductStore'
import { AppMainView, AppView } from '../../../components/AppView'
import { inject, observer } from 'mobx-react'
import { CurrentCompanyStore } from '../../../stores/CurrentCompanyStore'
import { uploadImageAndGetCloudinaryUrl, GrRegex } from '../../../utilities/helpers'
import { CLOUDINARY } from '../../../constants/general'
import { taxApi, TaxModels, provinceProductsApi } from '@getgreenline/products'
import { COLORS } from '../../../constants/Colors'
import { CSVProductHeaders } from '../../../constants/CSVHeaders'
import { SpecialCharacterProducts } from './SpecialCharacterProducts'
import { CompaniesModels } from '@getgreenline/companies'

export interface ICSVProductImport {
  imageUrl: string | null
  name: string
  categoryName: string | null
  vendorName: string | null
  description: string | null
  receiptDescription: string | null
  price: number | null
  taxGroupId: number | null
  sku: string | null
  parentSku: string | null
  parentName: string | null
  cost: number | null
  barcode: string | null
  cannabisWeight: number | null
  cannabisVolume: number | null
  weight: number | null
  isBatchTracked: boolean
  metadata: Array<{
    key: string
    value: string
  }>
}

interface INestedCSVProduct extends ICSVProductImport {
  childProducts: ICSVProductImport[]
}

interface Props {
  currentCompanyStore?: CurrentCompanyStore
  params: {
    companyId: string
  }
}

interface State {
  company?: ICompany
  suppliers?: Supplier[]
  categories?: ICategoryContract[]
  taxGroups?: TaxModels.TaxGroup[]
  data?: any[]
  defaultTaxGroup?: number
  defaultCategory?: string
  useProvinceCatalog: boolean
  isImporting: boolean
  importedRows: number
  skippedRows: any[]
  skipCloudinary: boolean
}

export function getIndex(array: string[], value: string): number | null {
  const index = array.map((e) => (e || '').toUpperCase()).indexOf(value.toUpperCase())
  if (index === -1) {
    // Not found. In JS, the result is -1.
    return null
  } else {
    return index
  }
}

function getValue(row: any, index: number | null) {
  if (!row || index === null) {
    return null
  }
  const rawValue = row[index]
  if (!rawValue || rawValue === '') {
    return null
  } else {
    return rawValue
  }
}

@inject('currentCompanyStore')
@observer
export class AdminProductImport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isImporting: false,
      useProvinceCatalog: false,
      importedRows: 0,
      skippedRows: [],
      skipCloudinary: false,
    }
  }

  componentDidMount() {
    this.loadCompany()
  }

  async loadCompany() {
    const { params } = this.props
    const companyId = parseInt(params.companyId)
    const company = await API.getCompanyById(companyId, false)
    this.setState({
      company: company,
    })
    this.loadCategories()
    this.loadVendors(company)
    this.loadTaxGroups()
  }

  async loadCategories() {
    const { company } = this.state
    if (company) {
      const categories = await API.getCategories(company.id)
      this.setState({
        categories: categories,
      })
    }
  }

  async loadVendors(company: ICompany) {
    const suppliers = await API.getSuppliers(company.id)
    this.setState({
      suppliers,
    })
    return suppliers
  }

  async loadTaxGroups() {
    const { company } = this.state
    if (company) {
      const taxGroups = await taxApi.getTaxGroups(company.id)
      this.setState({
        taxGroups: taxGroups,
      })
    }
  }

  onFileLoad(data: any) {
    if (data.length < 1) {
      return
    }
    this.setState({ data: data })
  }

  // Get back a flat list of standalone products
  get csvProducts(): ICSVProductImport[] {
    if (this.state.data) {
      const header: string[] = this.state.data[0]
      const dataWithoutHeader = this.state.data.slice(1, this.state.data.length)

      // Required fields
      const skuIndex = getIndex(header, CSVProductHeaders.SKU)
      const nameIndex = getIndex(header, CSVProductHeaders.NAME)
      const retailPriceIndex = getIndex(header, CSVProductHeaders.PRICE)
      const categoryIndex = getIndex(header, CSVProductHeaders.CATEGORY)

      // Optional fields
      const imageIndex = getIndex(header, CSVProductHeaders.IMAGE_URL)
      const parentSKUIndex = getIndex(header, CSVProductHeaders.PARENT_SKU)
      const parentNameIndex = getIndex(header, CSVProductHeaders.PARENT_NAME)
      const wholesaleCostIndex = getIndex(header, CSVProductHeaders.PURCHASE_PRICE)
      const descriptionIndex = getIndex(header, CSVProductHeaders.DESCRIPTION)
      const receiptDescriptionIndex = getIndex(header, CSVProductHeaders.SHORT_DESCRIPTION)
      const vendorIndex = getIndex(header, CSVProductHeaders.BRAND)
      const barcodeIndex = getIndex(header, CSVProductHeaders.BARCODE)
      const cannabisWeightIndex = getIndex(header, CSVProductHeaders.CANNABIS_WEIGHT)
      const cannabisVolumeIndex = getIndex(header, CSVProductHeaders.CANNABIS_VOLUME)
      const weightIndex = getIndex(header, CSVProductHeaders.WEIGHT)
      const thcIndex = getIndex(header, CSVProductHeaders.THC)
      const cbdIndex = getIndex(header, CSVProductHeaders.CBD)
      const minTHCIndex = getIndex(header, CSVProductHeaders.MIN_THC)
      const maxTHCIndex = getIndex(header, CSVProductHeaders.MAX_THC)
      const minCBDIndex = getIndex(header, CSVProductHeaders.MIN_CBD)
      const maxCBDIndex = getIndex(header, CSVProductHeaders.MAX_CBD)
      const unitIndex = getIndex(header, CSVProductHeaders.UNIT)
      const showMinMaxTHCCBDIndex = getIndex(header, CSVProductHeaders.SHOW_MAX_MIN)
      const trackLotsIndex = getIndex(header, CSVProductHeaders.TRACK_LOTS)
      const customHeaders = header.filter((h) => h.includes(CSVProductHeaders.CUSTOM_HEADER_PREFIX))
      const customHeaderIndexes = []
      for (const custom of customHeaders) {
        customHeaderIndexes.push(header.indexOf(custom))
      }

      const csvProducts: ICSVProductImport[] = []

      for (const row of dataWithoutHeader) {
        if (!isNumber(nameIndex)) {
          continue
        }

        const csvImage = getValue(row, imageIndex)
        const csvName = getValue(row, nameIndex)
        const csvCategory = getValue(row, categoryIndex)
        const csvVendor = getValue(row, vendorIndex)
        const csvSKU = getValue(row, skuIndex)
        const csvParentSKU = getValue(row, parentSKUIndex)
        const csvParentName = getValue(row, parentNameIndex)
        const csvDescription = getValue(row, descriptionIndex)
        const csvReceiptDescription = getValue(row, receiptDescriptionIndex)
        const csvBarcode = getValue(row, barcodeIndex)
        const cannabisWeight = getValue(row, cannabisWeightIndex)
          ? parseFloat(getValue(row, cannabisWeightIndex))
          : null
        const cannabisVolume = getValue(row, cannabisVolumeIndex)
          ? parseFloat(getValue(row, cannabisVolumeIndex))
          : null
        const weight = getValue(row, weightIndex) ? parseFloat(getValue(row, weightIndex)) : null
        const isBatchTracked = (getValue(row, trackLotsIndex) || '').toUpperCase().trim() === 'TRUE'
        let csvPrice = getValue(row, retailPriceIndex)
        let csvCost = getValue(row, wholesaleCostIndex)
        const customFields = customHeaderIndexes.map((index) => {
          return {
            key: header[index].replace(CSVProductHeaders.CUSTOM_HEADER_PREFIX, ''),
            value: row[index],
          }
        })
        const thc = getValue(row, thcIndex)
        if (thc) {
          customFields.push({
            key: MetaDataReservedKeys.THC,
            value: thc,
          })
        }
        const cbd = getValue(row, cbdIndex)
        if (cbd) {
          customFields.push({
            key: MetaDataReservedKeys.CBD,
            value: cbd,
          })
        }
        const minTHC = getValue(row, minTHCIndex)
        const maxTHC = getValue(row, maxTHCIndex)
        if (minTHC || maxTHC) {
          customFields.push({
            key: MetaDataReservedKeys.MIN_THC,
            value: minTHC,
          })
          customFields.push({
            key: MetaDataReservedKeys.MAX_THC,
            value: maxTHC,
          })
        }
        const minCBD = getValue(row, minCBDIndex)
        const maxCBD = getValue(row, maxCBDIndex)
        if (minCBD || maxCBD) {
          customFields.push({
            key: MetaDataReservedKeys.MIN_CBD,
            value: minCBD,
          })
          customFields.push({
            key: MetaDataReservedKeys.MAX_CBD,
            value: maxCBD,
          })
        }

        const unit = getValue(row, unitIndex)
        if (unit) {
          customFields.push({
            key: MetaDataReservedKeys.UNIT,
            value: unit,
          })
        }
        const showMinMaxTHCCBD = getValue(row, showMinMaxTHCCBDIndex)
          ? getValue(row, showMinMaxTHCCBDIndex).toUpperCase().trim() === 'TRUE'
          : false
        if (showMinMaxTHCCBD) {
          customFields.push({
            key: MetaDataReservedKeys.SHOW_MAX_MIN,
            value: true,
          })
        }

        if (!csvName) {
          console.log('Skipping, empty values')
          continue
        }

        if (isString(csvPrice)) {
          const number = parseFloat(csvPrice.replace('$', '')) || 0
          // Assume prices are in dollars
          csvPrice = Math.round(number * 100)
        } else if (isNumber(csvPrice)) {
          const number = csvPrice
          csvPrice = Math.round(number * 100)
        }

        if (csvCost) {
          if (isString(csvCost)) {
            const number = parseFloat(csvCost.replace('$', '')) || 0
            // Assume prices are in dollars
            csvCost = Math.round(number * 100)
          } else if (isNumber(csvCost)) {
            const number = csvCost
            csvCost = Math.round(number * 100)
          }
        }

        // Finds all characters that are not alphanumeric or these special characters
        const regexCharacters = /[^ A-Za-z0-9_@./#&+"'?!:()%*|`$^-]/g

        const stringifiedProduct = JSON.stringify({
          imageUrl: csvImage || null,
          name: csvName.replace(regexCharacters, ''),
          parentSku: csvParentSKU,
          parentName: csvParentName,
          description: csvDescription,
          receiptDescription: csvReceiptDescription,
          price: csvPrice,
          cost: csvCost,
          categoryName: csvCategory,
          vendorName: csvVendor,
          taxGroupId: this.state.defaultTaxGroup || null,
          barcode: csvBarcode,
          cannabisWeight: cannabisWeight,
          cannabisVolume: cannabisVolume,
          weight: weight,
          isBatchTracked,
          metadata: customFields,
        })

        const cleanedProduct = GrRegex.cleanFrequentSpecialCharacters(stringifiedProduct, true)

        csvProducts.push({ ...JSON.parse(cleanedProduct), sku: csvSKU })
      }

      return csvProducts
    } else {
      return []
    }
  }

  getNestedMappedProducts(): INestedCSVProduct[] {
    if (this.state.data) {
      const nestedCSVProducts: INestedCSVProduct[] = []

      for (const csvProduct of this.csvProducts) {
        if (csvProduct.parentName) {
          const matchingParentProduct = nestedCSVProducts.find(
            (product) => product.name === csvProduct.parentName,
          )
          if (matchingParentProduct) {
            // Add to already existing parent product
            matchingParentProduct.childProducts.push(csvProduct)
          } else {
            // Add the parent product first, then add variant
            nestedCSVProducts.push({
              sku: csvProduct.parentSku,
              name: csvProduct.parentName,
              categoryName: csvProduct.categoryName,
              vendorName: csvProduct.vendorName,
              taxGroupId: csvProduct.taxGroupId,
              childProducts: [csvProduct],
              imageUrl: null,
              description: null,
              receiptDescription: null,
              price: null,
              parentName: null,
              parentSku: null,
              cost: null,
              barcode: null,
              cannabisWeight: null,
              cannabisVolume: null,
              weight: null,
              isBatchTracked: csvProduct.isBatchTracked,
              metadata: [],
            })
          }
        } else {
          nestedCSVProducts.push({ ...csvProduct, childProducts: [] })
        }
      }

      return nestedCSVProducts
    } else {
      return []
    }
  }

  submit = async () => {
    const company = this.state.company

    if (company && window.confirm('Are you sure? Please stay on this page while importing.')) {
      const flattenedCategories: IBaseCategory[] = []
      const categories = this.state.categories || []
      for (const category of categories) {
        flattenedCategories.push(category)
        for (const childCategory of category.childCategories) {
          flattenedCategories.push(childCategory)
        }
      }
      const vendors = this.state.suppliers || []

      if (this.state.data) {
        this.setState({ isImporting: true })

        // Enable refresh/navigation confirm prompt
        window.onbeforeunload = function () {
          return true
        }

        const csvProducts = this.getNestedMappedProducts()

        for (const csvProduct of csvProducts) {
          // This import supports "parent - child" nested categories
          let categoryId = this.state.defaultCategory || null
          if (csvProduct.categoryName && csvProduct.categoryName.includes(' - ')) {
            const [parentCategoryName, childCategoryName] = csvProduct.categoryName.split(' - ')
            const parentCategory = categories.find((c) => c.name === parentCategoryName)
            if (parentCategory) {
              const childCategory = parentCategory.childCategories.find(
                (c) => c.name.toLowerCase() === childCategoryName.toLowerCase(),
              )
              categoryId = childCategory ? childCategory.id : null
            }
          } else if (csvProduct.categoryName) {
            const matchingCategory = flattenedCategories.find(
              (c) => c.name.toLowerCase() === csvProduct.categoryName!.toLowerCase(),
            )
            categoryId = matchingCategory ? matchingCategory.id : categoryId
          }

          // Auto-create a vendor if no match is found
          let vendorId: number | null = null
          const matchingVendor = vendors.find((v) => v.name === csvProduct.vendorName)
          if (matchingVendor) {
            vendorId = matchingVendor.id
          } else if (csvProduct.vendorName) {
            const createVendor = new CreateSupplier()
            createVendor.setName(csvProduct.vendorName)
            const newVendor = await API.addSupplier(company.id, createVendor)
            vendorId = newVendor.id
            vendors.push(newVendor)
          }

          const product = new CreateProduct()
          if (
            csvProduct.imageUrl &&
            !csvProduct.imageUrl.includes(CLOUDINARY) &&
            !this.state.skipCloudinary
          ) {
            try {
              const cloudinaryUrl = await uploadImageAndGetCloudinaryUrl(
                csvProduct.imageUrl,
                this.props.currentCompanyStore!,
              )
              product.setImageUrl(cloudinaryUrl)
            } catch (error) {
              product.setImageUrl(null)
              console.log(error)
            }
          } else {
            product.setImageUrl(csvProduct.imageUrl)
          }
          product.setInventoryMode(true)
          product.setName(csvProduct.name)
          product.setSKU(csvProduct.sku)
          if (csvProduct.description) {
            product.setDescription(csvProduct.description)
          }
          product.setPrice(csvProduct.price)
          product.setPurchasePrice(csvProduct.cost)
          product.setCategoryId(categoryId)
          product.setSupplierId(vendorId)
          product.setTaxGroupId(csvProduct.taxGroupId)
          product.setBarcode(csvProduct.barcode)
          product.setCannabisWeight(csvProduct.cannabisWeight)
          product.setCannabisVolume(csvProduct.cannabisVolume)
          product.setWeight(csvProduct.weight)

          if (company.isBatchTracking && product.inventoryMode !== 'bulk') {
            product.setIsBatchTracked(csvProduct.isBatchTracked)
          }

          csvProduct.metadata.forEach((meta) => {
            product.metaData.setValue(meta.key, meta.value)
          })

          if (csvProduct.childProducts.length > 0) {
            product.setInventoryMode('prepack')
            // Parent products should not have any metadata
            product.metaData = new ProductMetaData()
            for (const csvChildProduct of csvProduct.childProducts) {
              const childProduct = product.addChildProduct()
              if (
                csvChildProduct.imageUrl &&
                !csvChildProduct.imageUrl.includes(CLOUDINARY) &&
                !this.state.skipCloudinary
              ) {
                try {
                  const cloudinaryUrl = await uploadImageAndGetCloudinaryUrl(
                    csvChildProduct.imageUrl,
                    this.props.currentCompanyStore!,
                  )
                  childProduct.setImageUrl(cloudinaryUrl)
                } catch (error) {
                  childProduct.setImageUrl(null)
                  console.log(error)
                }
              } else {
                childProduct.setImageUrl(csvChildProduct.imageUrl || product.imageUrl)
              }
              childProduct.setName(csvChildProduct.name)
              childProduct.setSKU(csvChildProduct.sku)
              if (csvChildProduct.description) {
                childProduct.setDescription(csvChildProduct.description)
              }
              childProduct.setPrice(csvChildProduct.price)
              childProduct.setPurchasePrice(csvChildProduct.cost)
              childProduct.setBarcode(csvChildProduct.barcode)
              childProduct.setCannabisWeight(csvChildProduct.cannabisWeight)
              childProduct.setCannabisVolume(csvChildProduct.cannabisVolume)
              childProduct.setWeight(csvChildProduct.weight)
              csvChildProduct.metadata.forEach((meta) => {
                childProduct.metaData.setValue(meta.key, meta.value)
              })
            }
          }

          // If the user wants to use provincial product catalogs, fetch and populate if csv value doesn't exist
          const productsToPopulate: Array<CreateProduct | CreateChildProduct> = []
          if (product.isMasterProduct) {
            product.childProducts.forEach((c) => productsToPopulate.push(c))
          } else {
            productsToPopulate.push(product)
          }
          for (const product of productsToPopulate) {
            if (this.state.useProvinceCatalog && product.sku) {
              const results = await provinceProductsApi.getProvinceProducts(
                company.province as CompaniesModels.Province,
                product.sku,
              )
              if (results.length > 0) {
                const provinceResult = results[0]
                product.setImageUrl(product.imageUrl || provinceResult.imageUrl)
                product.setBarcode(product.barcode || provinceResult.barcode)
                product.setDescription(product.description || provinceResult.description)
                product.setCannabisWeight(product.cannabisWeight || provinceResult.cannabisWeight)
                product.setCannabisVolume(product.cannabisVolume || provinceResult.volume)
                product.setWeight(product.weight || provinceResult.weight)
                product.metaData.setValue(MetaDataReservedKeys.SHOW_MAX_MIN, 'true')
                product.metaData.setValue(MetaDataReservedKeys.MIN_THC, provinceResult.minThc)
                product.metaData.setValue(MetaDataReservedKeys.MAX_THC, provinceResult.maxThc)
                product.metaData.setValue(MetaDataReservedKeys.MIN_CBD, provinceResult.minCbd)
                product.metaData.setValue(MetaDataReservedKeys.MAX_CBD, provinceResult.maxCbd)
                product.metaData.setValue(MetaDataReservedKeys.UNIT, '%')
              }
            }
          }

          try {
            const createdProduct = await API.addProduct(company.id, product.networkObject)
            console.log(createdProduct)
            this.setState({ importedRows: this.state.importedRows + 1 })
            console.log('Importing')
          } catch (error) {
            console.error(error)
            this.setState({
              skippedRows: [...this.state.skippedRows, product.networkObject],
            })
            console.log('Skipping, empty values')
          }
        }

        // Remove refresh/navigation confirm prompt
        window.onbeforeunload = null
      }
    }
  }

  private headerMappingDisplay(headers: string[], columnName: string, isIgnored?: boolean) {
    const index = getIndex(headers, columnName)
    let type = index === null ? 'close-circle' : 'check-circle'
    let color = index === null ? COLORS.red400 : COLORS.green400

    if (isIgnored) {
      type = 'exclamation-circle'
      color = COLORS.amber
    }

    return (
      <div key={columnName}>
        <Icon type={type} theme='twoTone' twoToneColor={color} />
        <span className='ml-2'>{columnName}</span>
      </div>
    )
  }

  render() {
    const company = this.state.company

    if (!company) {
      return (
        <AppView column>
          <AppMainView>
            <Skeleton active />
          </AppMainView>
        </AppView>
      )
    }

    const { importedRows, skippedRows } = this.state

    const mappedNestedProducts = this.getNestedMappedProducts()

    const categories = this.state.categories || []
    const flattenedCategories: IBaseCategory[] = []
    for (const category of categories) {
      flattenedCategories.push(category)
      for (const childCategory of category.childCategories) {
        flattenedCategories.push(childCategory)
      }
    }
    const taxGroups = this.state.taxGroups || []

    const { data } = this.state

    let headerMapping
    if (data) {
      const headers: string[] = data[0]
      const customHeaders = headers.filter((h) =>
        h.includes(CSVProductHeaders.CUSTOM_HEADER_PREFIX),
      )
      const customHeaderIndexes = []
      for (const header of customHeaders) {
        customHeaderIndexes.push(header.indexOf(header))
      }

      headerMapping = (
        <>
          <div className='d-flex justify-content-between'>
            <b>Headers</b>
            <span>
              <Icon type='check-circle' theme='twoTone' twoToneColor={COLORS.green400} />
              <span className='ml-1 mr-2'>present</span>
              <Icon type='close-circle' theme='twoTone' twoToneColor={COLORS.red400} />
              <span className='ml-1 mr-2'>missing</span>
              <Icon type='exclamation-circle' theme='twoTone' twoToneColor={COLORS.amber} />
              <span className='ml-1 mr-2'>ignored</span>
            </span>
          </div>
          {this.headerMappingDisplay(headers, CSVProductHeaders.SKU)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.NAME)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.PRICE)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.CATEGORY)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.IMAGE_URL)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.PARENT_SKU)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.PARENT_NAME)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.PURCHASE_PRICE)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.DESCRIPTION)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.SHORT_DESCRIPTION)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.BRAND)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.BARCODE)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.CANNABIS_WEIGHT)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.CANNABIS_VOLUME)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.WEIGHT)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.SHOW_MAX_MIN)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.THC)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.CBD)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.MIN_THC)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.MAX_THC)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.MIN_CBD)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.MAX_CBD)}
          {this.headerMappingDisplay(headers, CSVProductHeaders.UNIT)}
          {this.headerMappingDisplay(
            headers,
            CSVProductHeaders.TRACK_LOTS,
            !this.state.company!.isBatchTracking,
          )}
          {customHeaders.map((c) => this.headerMappingDisplay(headers, c))}
        </>
      )
    }

    let categoryMapping
    if (data) {
      const header: string[] = data[0]
      const dataWithoutHeader = data.slice(1, data.length)
      const categoryIndex = getIndex(header, 'Category')
      if (categoryIndex !== null) {
        const uniqueCategories = uniq(dataWithoutHeader.map((data) => data[categoryIndex]))
        categoryMapping = (
          <>
            {uniqueCategories
              .filter((categoryName) => !!categoryName)
              .map((categoryName) => {
                let categoryId = this.state.defaultCategory || null
                if (categoryName && categoryName.includes(' - ')) {
                  const [parentCategoryName, childCategoryName] = categoryName.split(' - ')
                  const parentCategory = categories.find((c) => c.name === parentCategoryName)
                  if (parentCategory) {
                    const childCategory = parentCategory.childCategories.find(
                      (c) => c.name.toLowerCase() === childCategoryName.toLowerCase(),
                    )
                    categoryId = childCategory ? childCategory.id : null
                  }
                } else if (categoryName) {
                  const matchingCategory = flattenedCategories.find(
                    (c) => c.name.toLowerCase() === categoryName.toLowerCase(),
                  )
                  categoryId = matchingCategory ? matchingCategory.id : null
                }
                return (
                  <div key={categoryName}>
                    <Icon
                      type='check-circle'
                      theme='twoTone'
                      twoToneColor={categoryId === null ? '#eb2f96' : '#52c41a'}
                    />
                    <span className='ml-2'>{categoryName}</span>
                  </div>
                )
              })}
          </>
        )
      }
    }

    let nameWarnings
    // When saving CSV files in Excel, large numbers may be automatically converted to scientific notation
    const badNames = this.csvProducts.filter((p) => !p.name)
    if (badNames.length > 0) {
      nameWarnings = (
        <Alert
          type='warning'
          message='Name warnings'
          style={{ marginBottom: 10 }}
          description={
            <>
              <b>Please check the name of the following rows:</b>
              <ul>
                {badNames.map((b, index) => (
                  <li
                    key={index}
                  >{`${CSVProductHeaders.SKU}: ${b.sku}, ${CSVProductHeaders.NAME}: ${b.name}`}</li>
                ))}
              </ul>
            </>
          }
        />
      )
    }

    let barcodeWarnings
    // When saving CSV files in Excel, large numbers may be automatically converted to scientific notation
    const badBarcodes = this.csvProducts.filter(
      (p) => p.barcode && (p.barcode.includes('E') || p.barcode.includes('+')),
    )
    if (badBarcodes.length > 0) {
      barcodeWarnings = (
        <Alert
          type='warning'
          message='Barcode warnings'
          style={{ marginBottom: 10 }}
          description={
            <>
              <b>Please check the barcodes of the following rows:</b>
              <ul>
                {badBarcodes.map((b, index) => (
                  <li
                    key={index}
                  >{`${CSVProductHeaders.SKU}: ${b.sku}, ${CSVProductHeaders.NAME}: ${b.name}, ${CSVProductHeaders.BARCODE}: ${b.barcode}`}</li>
                ))}
              </ul>
            </>
          }
        />
      )
    }

    return (
      <AppView column>
        <PageHeader
          title={company ? `${company.name} - Product CSV import` : 'Loading...'}
          subTitle={company ? company.province : ''}
          onBack={() =>
            browserHistory.push(company ? `/admin/companies/${company.id}` : `/admin/companies`)
          }
        />
        <AppMainView>
          <div className='container'>
            <Alert
              type='info'
              message='CSV template'
              description={
                <>
                  <p>
                    Importing products requires the following columns to be available
                    (case-sensitive):
                  </p>
                  <div className='row'>
                    <div className='col-md-6'>
                      <p>
                        <b>Required CSV columns</b>
                      </p>
                      <ul>
                        <li>
                          {CSVProductHeaders.SKU} <span className='text-danger'>*required</span>
                        </li>
                        <li>
                          {CSVProductHeaders.NAME} <span className='text-danger'>*required</span>
                        </li>
                        <ul>
                          <li>
                            For parent products with variants, include a row for the parent and one
                            for each variant
                          </li>
                          <li>For variants, you must include a parent SKU</li>
                        </ul>
                        <li>
                          {CSVProductHeaders.PRICE} <span className='text-danger'>*required</span>
                        </li>
                        <ul>
                          <li>Can optionally include the $ symbol</li>
                        </ul>
                        <li>
                          {CSVProductHeaders.CATEGORY}{' '}
                          <span className='text-danger'>*required</span>
                        </li>
                        <ul>
                          <li>Categories must be created ahead of time</li>
                          <li>
                            Subcategories can be identified using a - separator (for ex. Dried
                            Flower - Indica)
                          </li>
                        </ul>
                      </ul>
                    </div>
                    <div className='col-md-6'>
                      <p>
                        <b>Optional CSV columns</b>
                      </p>
                      <ul>
                        <li>{CSVProductHeaders.IMAGE_URL}</li>
                        <li>{CSVProductHeaders.PARENT_SKU}</li>
                        <li>{CSVProductHeaders.PARENT_NAME}</li>
                        <ul>
                          <li>Required for parent/variant relationships</li>
                        </ul>
                        <li>{CSVProductHeaders.PURCHASE_PRICE}</li>
                        <ul>
                          <li>Can optionally include the $ symbol</li>
                        </ul>
                        <li>{CSVProductHeaders.DESCRIPTION}</li>
                        <li>{CSVProductHeaders.SHORT_DESCRIPTION}</li>
                        <li>{CSVProductHeaders.BRAND}</li>
                        <ul>
                          <li>If the vendor doesn't exist, will be auto-created</li>
                        </ul>
                        <li>{CSVProductHeaders.BARCODE}</li>
                        <li>{CSVProductHeaders.CANNABIS_WEIGHT}</li>
                        <li>{CSVProductHeaders.CANNABIS_VOLUME}</li>
                        <li>{CSVProductHeaders.WEIGHT}</li>
                        <li>{CSVProductHeaders.SHOW_MAX_MIN}</li>
                        <ul>
                          <li>true or false</li>
                          <li>If true, min/max THC/CBD values will be used</li>
                          <li>If false, single THC/CBD values will be used</li>
                        </ul>
                        <li>{CSVProductHeaders.THC}</li>
                        <li>{CSVProductHeaders.CBD}</li>
                        <li>{CSVProductHeaders.MIN_THC}</li>
                        <li>{CSVProductHeaders.MAX_THC}</li>
                        <li>{CSVProductHeaders.MIN_CBD}</li>
                        <li>{CSVProductHeaders.MAX_CBD}</li>
                        <li>{CSVProductHeaders.UNIT}</li>
                        <ul>
                          <li>Example: mg/g</li>
                        </ul>
                        <li>{CSVProductHeaders.TRACK_LOTS}</li>
                        <ul>
                          <li>true or false</li>
                          {!company.isBatchTracking && (
                            <li>
                              <span>
                                Will be <b>ignored</b> because lot-tracking is{' '}
                              </span>
                              <Tag className='mr-1' color='red'>
                                disabled
                              </Tag>
                              <span>at the company level. </span>
                              <span>
                                Enable company level lot-tracking before importing products
                              </span>
                            </li>
                          )}
                          <li>
                            Once enabled, product level lot-tracking <b>cannot</b> be disabled again
                          </li>
                        </ul>
                        <li>{CSVProductHeaders.CUSTOM_HEADER_PREFIX}[YOUR CUSTOM FIELD HERE]</li>
                      </ul>
                    </div>
                  </div>
                </>
              }
            />

            <br />

            {!data && (
              <CSVReader
                cssClass='csv-input'
                onFileLoaded={(data: any) => {
                  this.onFileLoad(data)
                }}
                onError={(error: any) => {
                  console.error('error')
                }}
                inputId='csv-id'
              />
            )}

            {data && this.state.isImporting && (
              <div className='container'>
                <Progress
                  percent={Math.round(
                    ((importedRows + skippedRows.length) / mappedNestedProducts.length) * 100,
                  )}
                />
                <div className='text-success'>Imported products: {importedRows}</div>
                <div className='text-danger'>Skipped/errored products: {skippedRows.length}</div>
                <div>Total products: {mappedNestedProducts.length}</div>
                <br />
                <div>Skipped/errored products log:</div>
                <div className='bg-light' style={{ maxHeight: 250, overflowY: 'scroll' }}>
                  <pre>{JSON.stringify(this.state.skippedRows, null, 2)}</pre>
                </div>
              </div>
            )}

            {data && !this.state.isImporting && (
              <div>
                <b>Number of products (not including variants):</b>
                <div>{mappedNestedProducts.length}</div>
                <br />

                {nameWarnings}
                {barcodeWarnings}

                <div className='row'>
                  <div className='col-md-6'>
                    {headerMapping}
                    <br />
                    <b>Category</b>
                    <div className='text-muted'>
                      The import will attach products to Greenline categories with the same name.
                      <br />
                      Separate categories and subcategories with a -
                      <br />
                      If no matching categories are found, the default category will be used.
                    </div>
                    <Select
                      value={this.state.defaultCategory}
                      clearable={true}
                      options={categories.map((c) => {
                        return {
                          label: c.name,
                          value: c.id,
                        }
                      })}
                      onChange={(option: { label: string; value: string } | null) => {
                        this.setState({ defaultCategory: option?.value })
                      }}
                    />
                    <br />
                    {categoryMapping}
                    <br />
                    <b>Tax group</b>
                    <div className='text-muted'>
                      Create your tax groups ahead of time under Products {'>'} Tax groups.
                    </div>
                    <Select
                      value={this.state.defaultTaxGroup}
                      clearable={true}
                      options={taxGroups.map((c) => {
                        return {
                          label: c.name,
                          value: c.id,
                        }
                      })}
                      onChange={(option: { label: string; value: number }) => {
                        this.setState({
                          defaultTaxGroup: option?.value,
                        })
                      }}
                    />
                    <br />
                    <div>
                      <Checkbox
                        checked={this.state.useProvinceCatalog}
                        onChange={() =>
                          this.setState({ useProvinceCatalog: !this.state.useProvinceCatalog })
                        }
                      >
                        Combine with provincial product catalog data (image, description, weights,
                        volumes, thc, and cbd) if a match exists.
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        checked={this.state.skipCloudinary}
                        onChange={() =>
                          this.setState({ skipCloudinary: !this.state.skipCloudinary })
                        }
                      >
                        Skip uploading images to cloudinary. This can speed up uploading (but will
                        not store images in cloudinary)
                      </Checkbox>
                    </div>
                    <br />
                    <div>
                      <SpecialCharacterProducts
                        csvProducts={this.csvProducts}
                        submit={this.submit}
                      />
                    </div>
                  </div>

                  <div className='col-md-6'>
                    <div>Preview (first 10 results)</div>
                    <div className='bg-light' style={{ maxHeight: 800, overflowY: 'scroll' }}>
                      <pre>
                        {JSON.stringify(this.getNestedMappedProducts().slice(0, 10), null, 2)}
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <br />
          </div>
        </AppMainView>
      </AppView>
    )
  }
}
