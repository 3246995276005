// API reference: https://developer.mixpanel.com/docs/javascript
const MIXPANEL_API_KEY = process.env.REACT_APP_MIXPANEL_API_KEY

if (MIXPANEL_API_KEY) {
  window.mixpanel.init(MIXPANEL_API_KEY)
}

export const Track = {
  identify: function (userId: string, value: object) {
    try {
      if (MIXPANEL_API_KEY) {
        window.mixpanel.people.set(value)
        window.mixpanel.identify(userId)
      }
      window.Intercom('boot', {
        user_id: userId,
        ...value,
      })
    } catch (error) {
      console.error(error)
    }
  },

  track: function (key: string, value?: any) {
    try {
      if (MIXPANEL_API_KEY) {
        window.mixpanel.track(key, value)
      }
      window.Intercom('trackEvent', key, value)
    } catch (error) {
      console.error(error)
    }
  },

  setGroup: function (companyId: string | number, fields: object) {
    try {
      if (MIXPANEL_API_KEY) {
        window.mixpanel.add_group('companyId', companyId)
        window.mixpanel.set_group('companyId', companyId)
        window.mixpanel.get_group('companyId', companyId).set(fields)
      }
      window.Intercom('update', {
        company: fields,
      })
    } catch (error) {
      console.error(error)
    }
  },

  // To be followed up with a Track.track() call with the same key
  startTimer: function (key: string) {
    try {
      if (MIXPANEL_API_KEY) {
        window.mixpanel.time_event(key)
      }
    } catch (error) {
      console.error(error)
    }
  },

  clear: function () {
    try {
      if (MIXPANEL_API_KEY) {
        window.mixpanel.reset()
      }
      window.Intercom('shutdown')
    } catch (error) {
      console.error(error)
    }
  },
}

export enum TrackEvent {
  DASHBOARD_SERVER_DISCONNECTED = 'dashboard:server_disconnected',
}
