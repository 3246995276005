import React, { useState } from 'react'
import { Link } from 'react-router'
import { Col, Row, message, Button } from 'antd-v4'
import Paragraph from 'antd-v4/lib/typography/Paragraph'
import Text from 'antd-v4/lib/typography/Text'
import useBreakpoint from 'antd-v4/lib/grid/hooks/useBreakpoint'
import { CurrentUserStore } from '../stores/CurrentUserStore'
import { Routes } from '../constants/Routes'
import Logo from '../images/logo-256.png'
import { LocalStorage } from '../utilities/LocalStorage'
import { COLORS } from '../constants/Colors'
import { CheckOrX } from '../components/CheckOrX'
import { CognitoErrorTypes, parseCongitoError } from '../utilities/cognito'
import { errorIsType } from '../utilities/errors'
interface Props {
  location: {
    query: Record<string, string>
  }
}

const hasMinEightCharacters = (text: string) => text.length >= 8
const hasOneSpecialCharacter = (text: string) => new RegExp(/\W/).test(text)
const hasOneNumber = (text: string) => new RegExp(/\d/).test(text)
const hasOneLowercaseLetter = (text: string) => new RegExp(/[a-z]/).test(text)
const hasOneUppercaseLetter = (text: string) => new RegExp(/[A-Z]/).test(text)
const hasOneUpperAndLowercaseLetter = (text: string) =>
  hasOneLowercaseLetter(text) && hasOneUppercaseLetter(text)

export const ResetPassword: React.FC<Props> = ({ location }: Props) => {
  const { query } = location
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [email, setEmail] = useState(LocalStorage.getUnauthenticatedEmail() || query.email || '')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [uniqueCode, setUniqueCode] = useState('')
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false)

  const { resetTemporaryPassword, resetEmployeePassword } = location.query
  const isConfirmSignup = !!resetTemporaryPassword // Renaming for clarity - needs to stay consistent with signup email query params
  const buttonMessage = isLoading ? 'Submitting...' : 'Submit'
  const passwordsMatch = password === confirmPassword
  const isPasswordValid =
    hasMinEightCharacters(password) &&
    hasOneSpecialCharacter(password) &&
    hasOneNumber(password) &&
    hasOneLowercaseLetter(password) &&
    hasOneUpperAndLowercaseLetter(password)
  const isButtonDisabled =
    isLoading ||
    !uniqueCode ||
    !password ||
    !confirmPassword ||
    !isPasswordValid ||
    !passwordsMatch ||
    (resetEmployeePassword === 'true' && !email)
  const shouldShowConfirmPasswordError =
    !confirmPasswordFocused && confirmPassword && !passwordsMatch

  const { lg } = useBreakpoint()

  const resetPassword = async () => {
    setIsLoading(true)

    try {
      if (!email) {
        throw new Error('Email is required. Please enter your email address and try again.')
      }
      if (!password) throw new Error('Password is required')
      if (!passwordsMatch) throw new Error('Passwords must match')
      if (!uniqueCode) {
        throw new Error('Verification code is required')
      }
      const lowerCaseEmail = email.toLowerCase()
      if (isConfirmSignup) {
        await CurrentUserStore.confirmSignup({
          email: lowerCaseEmail,
          verificationCode: uniqueCode,
          newPassword: password,
        })
      } else {
        await CurrentUserStore.resetPasswordV2({
          email: lowerCaseEmail,
          verificationCode: uniqueCode,
          newPassword: password,
        })
      }
      setIsSubmitted(true)
      message.success('Your password has been reset')
    } catch (error) {
      const errorMessage = errorIsType(error, CognitoErrorTypes.INVALID_PASSWORD)
        ? 'There was an issue resetting your password. Check the verification code and try again'
        : parseCongitoError(error)
      message.error(errorMessage)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='container pt-5'>
      <Link to={Routes.LOGIN_V2}>
        <img className='mr-3' src={Logo} style={{ width: '30px', height: '30px' }} />
        <span>Greenline POS</span>
      </Link>

      <br />

      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col span={lg ? 12 : 24} className='my-4'>
          <h1 className='display-4'>Reset Password</h1>
          <br />

          {isSubmitted ? (
            <Paragraph>
              Please login <Link to={Routes.LOGIN_V2}>here</Link>
            </Paragraph>
          ) : (
            <form>
              {!isConfirmSignup && (
                <>
                  <Paragraph>
                    If an account with your email exists in our system you will receive a
                    verification code in your inbox.
                  </Paragraph>
                  <Paragraph>
                    If you haven't received an email verification code after awhile, make sure your
                    email is correct and try again from{' '}
                    <Link to={Routes.FORGOT_PASSWORD_V2}>Forgot Password</Link>
                  </Paragraph>
                </>
              )}
              {resetEmployeePassword && (
                <div className='form-group'>
                  <label>Email</label>
                  <input
                    type='email'
                    className='form-control'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              )}
              <div className='form-group'>
                <label>{'Verification Code'}</label>
                <input
                  type={isConfirmSignup ? 'password' : 'text'}
                  className='form-control'
                  value={uniqueCode}
                  onChange={(e) => setUniqueCode(e.target.value)}
                  required
                />
              </div>
              <Row>
                <Col span={lg ? 11 : 24} style={{ marginRight: lg ? 13 : 0 }}>
                  <div className='form-group'>
                    <label>New password</label>
                    <input
                      type='password'
                      className='form-control'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        borderColor:
                          password && !isPasswordValid ? COLORS.dangerBorder : COLORS.borderGrey,
                      }}
                      required
                    />
                  </div>
                  <div className='form-group'>
                    <label>Confirm new password</label>
                    <input
                      type='password'
                      onFocus={() => setConfirmPasswordFocused(true)}
                      onBlur={() => setConfirmPasswordFocused(false)}
                      className='form-control'
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={{
                        borderColor:
                          confirmPassword && !passwordsMatch
                            ? COLORS.dangerBorder
                            : COLORS.borderGrey,
                      }}
                      required
                    />
                    <Text
                      style={{
                        fontSize: '0.65em',
                        color: COLORS.dangerTextColor,
                        opacity: shouldShowConfirmPasswordError ? 1 : 0,
                        transition: 'opacity 0.15s ease-in-out',
                      }}
                    >
                      * Passwords must match
                    </Text>
                  </div>
                </Col>
                <Col
                  span={lg ? 11 : 24}
                  style={{
                    border: `1px solid ${COLORS.borderGrey}`,
                    borderRadius: 4,
                    background: COLORS.grey100,
                    marginLeft: lg ? 13 : 0,
                    padding: 10,
                  }}
                >
                  <Paragraph>Password requires:</Paragraph>
                  <Paragraph>
                    <CheckOrX condition={hasMinEightCharacters} test={password} />
                    <span className='ml-2'>minimum 8 characters</span>
                  </Paragraph>
                  <Paragraph>
                    <CheckOrX condition={hasOneSpecialCharacter} test={password} />
                    <span className='ml-2'>1 special character</span>
                  </Paragraph>
                  <Paragraph>
                    <CheckOrX condition={hasOneNumber} test={password} />
                    <span className='ml-2'>1 number</span>
                  </Paragraph>
                  <Paragraph>
                    <CheckOrX condition={hasOneUpperAndLowercaseLetter} test={password} />
                    <span className='ml-2'>1 capital and lowercase letter</span>
                  </Paragraph>
                </Col>
              </Row>
              <br />
              <Button type='primary' onClick={resetPassword} disabled={isButtonDisabled}>
                {buttonMessage}
              </Button>
            </form>
          )}
        </Col>
      </Row>
    </div>
  )
}
