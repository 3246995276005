import { ILocation } from '@getgreenline/homi-shared'
import { LocationModels } from '@getgreenline/locations'
import { Icon } from 'antd'
import { Input, Select } from 'antd-v4'
import { observer } from 'mobx-react'
import React from 'react'
import { CannabinoidMetaDataItem, CreateChildProduct, CreateProduct } from '../ProductStore'
import { CurrentCompanyStore } from '../../../../../stores/CurrentCompanyStore'

interface Props {
  index: number
  disabled: boolean
  product: CreateProduct | CreateChildProduct
  cannabinoid: CannabinoidMetaDataItem
  orderedPermittedLocations: ILocation[]
  mappedGlobalLocations: Map<number, LocationModels.IGlobalLocationContract>
  dropdownMatchSelectWidth?: boolean
  currentCompanyStore?: CurrentCompanyStore
}

interface State {
  searchedLocationName?: string
}

@observer
export class LocationsSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      searchedLocationName: undefined,
    }
  }

  dropdownRender = (menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => (
    <div className='ml-1 mr-1'>
      {menu}
      <Input
        className='mt-1'
        value={this.state.searchedLocationName}
        placeholder='Search'
        prefix={<Icon type='search' />}
        // https://github.com/ant-design/ant-design/issues/15392
        onKeyDown={(event) => {
          if (event.key === 'Backspace') {
            return event.stopPropagation()
          }
        }}
        onChange={(e) => this.setState({ searchedLocationName: e.target.value })}
      />
    </div>
  )

  onSelect = (locationId: number) => {
    const { product, cannabinoid } = this.props
    const { cannabinoidMetaData } = product

    if (cannabinoid.locationIds.includes(locationId)) {
      return cannabinoid.removeLocationId(locationId)
    }

    const existingCannabinoidMetaData =
      cannabinoidMetaData.locationMappedCannabinoidMetaData.get(locationId)

    if (existingCannabinoidMetaData) {
      const confirmed = window.confirm(this.getConfirmMessage(locationId))

      if (!confirmed) return

      existingCannabinoidMetaData.removeLocationId(locationId)
    }

    cannabinoid.addLocationId(locationId)

    this.props.product.changed = true
  }

  getConfirmMessage = (locationId: number) => {
    const locationName = this.props.mappedGlobalLocations.get(locationId)?.name

    let confirmMessage = locationName
      ? `${locationName} will be removed from its original profile. `
      : ''

    confirmMessage += 'Are you sure?'

    return confirmMessage
  }

  isLocationDisabled(locationId: number) {
    const { product } = this.props
    const { cannabinoidMetaData } = product

    const allLocationsUsed = cannabinoidMetaData.cannabinoids.map((cannabinoid) => {
      return cannabinoid === this.props.cannabinoid ? [] : cannabinoid.locationIds
    })

    let locations: number[] = []

    allLocationsUsed.forEach((location) => {
      locations = locations.concat(location)
    })
    return locations.includes(locationId)
  }

  get locationNotPermitted() {
    const { orderedPermittedLocations, cannabinoid } = this.props

    const allLocationIds = orderedPermittedLocations.map((location) => location.id)
    return cannabinoid.locationIds.some((locationId) => !allLocationIds.includes(locationId))
  }

  get searchedLocations() {
    return this.props.orderedPermittedLocations.filter((location) => {
      const lowerCaseInput = this.state.searchedLocationName?.toLowerCase()

      const isSelected = lowerCaseInput
        ? this.selectedLocations
            .map((location) => location.name)
            .find((locationName) => locationName.toLowerCase().indexOf(lowerCaseInput) >= 0)
        : false

      return lowerCaseInput
        ? location.name.toLowerCase().indexOf(lowerCaseInput) >= 0 && !isSelected
        : true
    })
  }

  get selectedLocations() {
    return [...this.props.mappedGlobalLocations.values()].filter((location) => {
      return this.props.cannabinoid.locationIds.includes(location.id)
    })
  }

  get unselectedLocations() {
    return [...this.props.orderedPermittedLocations].filter(
      (location) => !this.props.cannabinoid.locationIds.includes(location.id),
    )
  }

  get locationOptions() {
    return this.state.searchedLocationName ? this.searchedLocations : this.unselectedLocations
  }

  render() {
    const { disabled, product, cannabinoid } = this.props
    const { cannabinoidMetaData } = product

    return (
      <Select<number[]>
        showSearch={false}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
        allowClear
        mode='multiple'
        maxTagCount={1}
        maxTagTextLength={13}
        optionLabelProp='label'
        disabled={disabled || this.locationNotPermitted}
        className='w-100 ant-select-border-rounded'
        placeholder='Select locations'
        defaultValue={cannabinoid.locationIds}
        value={cannabinoid.locationIds}
        dropdownMatchSelectWidth={this.props.dropdownMatchSelectWidth || false}
        onBlur={() => this.setState({ searchedLocationName: undefined })}
        onSelect={this.onSelect}
        onDeselect={(locationId: number) => {
          cannabinoid.removeLocationId(locationId)
          product.changed = true
        }}
        onClear={() => {
          cannabinoid.setLocationIds([])
          product.changed = true
        }}
        dropdownRender={this.dropdownRender}
      >
        {this.selectedLocations.map((location, index) => {
          const className = index === this.selectedLocations.length - 1 ? 'mb-3' : undefined

          return (
            <Select.Option
              key={location.id}
              value={location.id}
              label={location.name}
              className={`${className} ant-select-item-option-selected`}
            >
              <div className='d-flex justify-content-between mr-2'>
                <span className='mr-3'>{location.name}</span>
                <span>{cannabinoid.thcCbdRangeInfo}</span>
              </div>
            </Select.Option>
          )
        })}

        {this.locationOptions.map((location) => {
          const existingCannabinoidMetaData =
            cannabinoidMetaData.locationMappedCannabinoidMetaData.get(location.id)
          return (
            <Select.Option
              key={location.id}
              value={location.id}
              label={location.name}
              disabled={
                this.props.currentCompanyStore?.productDetailsTerpenesAndCannabinoids &&
                this.isLocationDisabled(location.id)
              }
            >
              <div className='d-flex justify-content-between'>
                <span className='mr-3'>{location.name}</span>
                <span>{existingCannabinoidMetaData?.thcCbdRangeInfo || 'default THC & CBD'}</span>
              </div>
            </Select.Option>
          )
        })}
      </Select>
    )
  }
}
