import * as React from 'react'
import {
  Form,
  Input,
  Icon,
  Button,
  message,
  Switch,
  Upload,
  Select,
  Row,
  Col,
  List,
  Checkbox,
} from 'antd'
import { ChromePicker } from 'react-color'
import { FormComponentProps } from 'antd/lib/form'
import {
  ExternalSourceCategoryNames,
  ICreateExternalSource,
  IAdminExternalSource,
  API,
} from '@getgreenline/homi-shared'
import { useState } from 'react'
import { parseErrorMsg, uploadImageToCloudinary } from '../../../utilities/helpers'
import { SmartImage } from '../../../components/SmartImage'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'
import { CurrentCompanyStore } from '../../../stores/CurrentCompanyStore'
import { CloudinaryResponse } from '../../../components/ImageUploadModal'
import { ColProps } from 'antd/lib/col'
import { AdminExternalSourceStore, AvailablePermission } from './AdminExternalSourceStore'
import { DragDrop } from '../../../components/DragDrop/DragDrop'
import { DraggableItem } from '../../../components/DragDrop/DraggableItem'
import { LoadingAnimation } from '../../../components/LoadingAnimation'

interface Props extends FormComponentProps {
  existingExternalSource?: IAdminExternalSource
  formSpans: number[]
  labelCol?: ColProps
  currentCompanyStore?: CurrentCompanyStore
  adminExternalSourceStore?: AdminExternalSourceStore
  shouldResetFields?: boolean
  onSubmit: (externalSourceObject: ICreateExternalSource) => void
}

const ExternalSourceForm = inject('currentCompanyStore')(
  observer((props: Props) => {
    const {
      existingExternalSource,
      formSpans,
      labelCol,
      currentCompanyStore,
      adminExternalSourceStore,
      shouldResetFields,
      onSubmit,
      form,
    } = props

    const { getFieldDecorator } = form

    const [isLogoImageUploading, setIsLogoImageUploading] = useState(false)
    const [logoImageUrl, setLogoImageUrl] = useState<string | undefined>(
      existingExternalSource?.logoImageUrl || undefined,
    )
    const [color, setColor] = useState(existingExternalSource?.hexColor || '')

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      form.validateFields(
        async (err, values: Omit<ICreateExternalSource, 'color' | 'logoImageUrl'>) => {
          if (err) {
            return
          }

          try {
            onSubmit({
              name: values.name,
              hexColor: color,
              logoImageUrl,
              description: values.description,
              categoryName: values.categoryName,
              setupUrl: values.setupUrl,
              email: values.email,
              homepage: values.homepage,
              isMarketplace: values.isMarketplace,
              permissions: adminExternalSourceStore?.newPermissionObject,
            })

            if (shouldResetFields) {
              form.resetFields()
            }

            ;(adminExternalSourceStore?.checkedPermissions || []).forEach((checkedPermission) => {
              checkedPermission.setChecked(false)
            })
          } catch (error) {
            message.error(
              parseErrorMsg(error) || 'An error occurred while creating an external source',
            )
          }
        },
      )
    }

    return (
      <Form labelCol={labelCol} onSubmit={handleSubmit} className='mb-5'>
        <Row>
          <Col span={formSpans[0]} className='pr-4'>
            <Form.Item labelAlign='left' name='name' label='Name'>
              {getFieldDecorator('name', {
                initialValue: existingExternalSource?.name,
                rules: [{ required: true, message: 'Please input an external name' }],
              })(
                <Input
                  prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder='Name'
                />,
              )}
            </Form.Item>

            <Form.Item labelAlign='left' name='description' label='Description'>
              {getFieldDecorator('description', {
                initialValue: existingExternalSource?.description,
                rules: [{ required: false, message: 'Please input a description' }],
              })(<Input.TextArea placeholder='Description' rows={4} />)}
            </Form.Item>

            <Form.Item
              name='categoryName'
              labelAlign='left'
              label='Category'
              rules={[{ required: true }]}
            >
              {getFieldDecorator('categoryName', {
                initialValue: existingExternalSource?.categoryName,
                rules: [{ required: false }],
              })(
                <Select placeholder='Please select an option' allowClear>
                  {Object.keys(ExternalSourceCategoryNames).map((nameKey: string, index) => {
                    const categoryName = ExternalSourceCategoryNames[nameKey]

                    return (
                      <Select.Option key={index} value={categoryName}>
                        {categoryName}
                      </Select.Option>
                    )
                  })}
                </Select>,
              )}
            </Form.Item>

            <Form.Item labelAlign='left' name='setupUrl' label='Help article URL'>
              {getFieldDecorator('setupUrl', {
                initialValue: existingExternalSource?.setupUrl,
                rules: [{ required: false, message: 'Please input a help article URL' }],
              })(
                <Input
                  prefix={<Icon type='search' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder='Help article URL'
                />,
              )}
            </Form.Item>

            <Form.Item labelAlign='left' name='homepage' label='Homepage URL'>
              {getFieldDecorator('homepage', {
                initialValue: existingExternalSource?.homepage,
                rules: [{ required: false, message: 'Please input a homepage URL' }],
              })(
                <Input
                  prefix={<Icon type='home' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder='Homepage URL'
                />,
              )}
            </Form.Item>

            <Form.Item labelAlign='left' name='email' label='Contact email'>
              {getFieldDecorator('email', {
                initialValue: existingExternalSource?.email,
                rules: [{ required: false, message: 'Please input an email' }],
              })(
                <Input
                  prefix={<Icon type='mail' style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder='Email'
                />,
              )}
            </Form.Item>

            <Form.Item labelAlign='left' name='isMarketplace' label='Enabled'>
              {getFieldDecorator('isMarketplace', {
                initialValue: !!existingExternalSource?.isMarketplace,
                rules: [{ required: true }],
              })(<Switch defaultChecked={!!existingExternalSource?.isMarketplace} />)}
            </Form.Item>

            <Form.Item name='logoImageUrl' labelAlign='left' label='Logo image'>
              <Upload
                name='avatar'
                listType='picture-card'
                className='avatar-uploader'
                showUploadList={false}
                beforeUpload={(file) => {
                  const isAllowedFile =
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png' ||
                    file.type === 'image/svg+xml'

                  if (!isAllowedFile) {
                    message.error('You can only upload JPG/PNG/SVG file!')
                  }

                  return isAllowedFile
                }}
                onChange={async (info) => {
                  if (info.file.status === 'error' || info.file.status === undefined) {
                    return
                  }

                  try {
                    setIsLogoImageUploading(true)

                    const uploadUrlDetails =
                      await currentCompanyStore!.getSignedAdminImageUploadPublicUrl()

                    const { xhr, formData } = uploadImageToCloudinary(
                      info.file.originFileObj,
                      uploadUrlDetails,
                    )

                    xhr.onload = () => {
                      setIsLogoImageUploading(false)

                      if (xhr.status === 200) {
                        const responseData: CloudinaryResponse = JSON.parse(xhr.responseText)

                        setLogoImageUrl(responseData.secure_url)
                      } else {
                        return message.error(xhr.statusText)
                      }
                    }
                    xhr.send(formData)
                  } catch (error) {
                    message.error(
                      parseErrorMsg(error) || 'An error occurred while uploading an image',
                    )
                  }
                }}
              >
                {logoImageUrl ? (
                  <SmartImage src={logoImageUrl} />
                ) : (
                  <div>
                    <Icon type={isLogoImageUploading ? 'loading' : 'plus'} />
                    <div className='ant-upload-text'>Upload</div>
                  </div>
                )}
              </Upload>
            </Form.Item>

            <Form.Item labelAlign='left' name='colour' label='Colour'>
              <div style={{ paddingTop: '3rem' }}>
                <ChromePicker
                  color={color}
                  onChangeComplete={(newColor) => setColor(newColor.hex)}
                />
              </div>
            </Form.Item>
          </Col>

          {adminExternalSourceStore && (
            <Col span={formSpans[1]}>
              <div style={{ marginTop: '12px', marginBottom: '18px' }}>
                Permissions
                <Button
                  className='ml-2'
                  type='primary'
                  onClick={async () => {
                    if (!adminExternalSourceStore?.updatedPermissionOrderObject) {
                      return
                    }

                    try {
                      const updatedExternalPermissions = await API.updateExternalPermissions(
                        adminExternalSourceStore.updatedPermissionOrderObject,
                      )

                      adminExternalSourceStore.setAvailablePermissions(updatedExternalPermissions)

                      message.success('Permission order successfully updated')
                    } catch (error) {
                      message.error(
                        parseErrorMsg(error) || 'An error occured while updating permission orders',
                      )
                    }
                  }}
                >
                  Update order
                </Button>
              </div>

              {adminExternalSourceStore.availablePermissions ? (
                <DragDrop<AvailablePermission>
                  array={adminExternalSourceStore.availablePermissions}
                  onAfterDrop={(reOrderedArray) => {
                    adminExternalSourceStore.availablePermissions = reOrderedArray
                  }}
                >
                  {(permissionItem, index) => (
                    <DraggableItem
                      key={index}
                      draggableId={permissionItem.permission.id.toString()}
                      position={index}
                    >
                      <List.Item className='cursor-pointer'>
                        <Checkbox
                          onChange={() => permissionItem.setChecked(!permissionItem.checked)}
                          checked={permissionItem.checked}
                        >
                          {permissionItem.permission.name}
                        </Checkbox>
                      </List.Item>
                    </DraggableItem>
                  )}
                </DragDrop>
              ) : (
                <LoadingAnimation green />
              )}
            </Col>
          )}
        </Row>

        <Row>
          <Button className='ml-2' type='primary' htmlType='submit'>
            Submit
          </Button>
        </Row>
      </Form>
    )
  }),
)

export default Form.create<Props>({ name: 'external_source_form' })(ExternalSourceForm)
